import { types } from "./W09F2023_actions";
import moment from "moment";
import _ from "lodash";

const initialState = {
    getForm: {},
};

const formatDate = (date) => {
    let dateFormatted = "";
    if (date) dateFormatted = "";
    if (moment(date).isValid()) {
        dateFormatted = moment(date).format("DD/MM/YYYY");
    }
    return dateFormatted;
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.W09F2023_GET_FROM_SUCCESS: {
            const data = _.get(action, "data[0]", {});
            return {
                ...state,
                getForm: {
                    ...data,
                    AbsentDateFrom: formatDate(data.AbsentDateFrom),
                    AbsentDateTo: formatDate(data.AbsentDateTo),
                    ChildBirthDate: formatDate(data.ChildBirthDate),
                    ExpectWorkDay: formatDate(data.ExpectWorkDay),
                    MaternityEndDate: formatDate(data.MaternityEndDate),
                    PlanChildDate: formatDate(data.PlanChildDate),
                },
            };
        }
        default:
            return state;
    }
}
