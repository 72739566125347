
export const types = {
    W09F2081_GET_DYNAMIC_COLUMN_SALARY: "W09F2080_GET_DYNAMIC_COLUMN_SALARY",
    W09F2081_GET_DYNAMIC_COLUMN_SALARY_SUCCESS: "W09F2080_GET_DYNAMIC_COLUMN_SALARY_SUCCESS",

};

export function getDynamicColumnSalary(params,cb) {
    return {
        type: types.W09F2081_GET_DYNAMIC_COLUMN_SALARY,
        params,
        cb
    };
}
