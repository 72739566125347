/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 9/25/2020
 * @Example
 */

export const types = {
    W09F2150_LOAD_DYNAMIC_FIELD: "W09F2150_LOAD_DYNAMIC_FIELD",
    W09F2150_LOAD_DYNAMIC_FIELD_SUCCESS: "W09F2150_LOAD_DYNAMIC_FIELD_SUCCESS",
    W09F2150_LOAD_GRID: "W09F2150_GET_DATA_GRID",
    W09F2150_LOAD_GRID_SUCCESS: "W09F2150_GET_DATA_GRID_SUCCESS",
    W09F2150_EDIT_DATA: "W09F2150_EDIT_DATA",
    W09F2150_SAVE_DATA: "W09F2150_SAVE_DATA",
    W09F2150_DELETE_DATA: "W09F2150_DELETE_DATA",
    W09F2150_GET_FORM: "W09F2150_GET_FORM",
    W09F2150_GET_FORM_SUCCESS: "W09F2150_GET_FORM_SUCCESS",
    W09F2150_LOAD_CAPTION: "W09F2150_LOAD_CAPTION",
    W09F2150_LOAD_CAPTION_SUCCESS: "W09F2150_LOAD_CAPTION_SUCCESS",
};

export function loadForm(params, cb) {
    return {
        type: types.W09F2150_GET_FORM,
        params,
        cb
    };
}

export function loadDataGrid(params, cb) {
    return {
        type: types.W09F2150_LOAD_GRID,
        params,
        cb
    };
}

export function loadDynamicFields(cb) {
    return {
        type: types.W09F2150_LOAD_DYNAMIC_FIELD,
        cb
    };
}

export function edit(params, cb) {
    return {
        type: types.W09F2150_EDIT_DATA,
        params,
        cb
    };
}

export function save(params, cb) {
    return {
        type: types.W09F2150_SAVE_DATA,
        params,
        cb
    };
}

export function deleteGrid(params, cb) {
    return {
        type: types.W09F2150_DELETE_DATA,
        params,
        cb
    };
}

export function loadCaption(params, cb) {
    return {
        type: types.W09F2150_LOAD_CAPTION,
        params,
        cb
    };
}

