import {types} from "./W00F0005_actions";

const initialState = {
    getDataFormEss: [],
};

export default function (state = initialState, action = {}) {
    if (action.type === types.W00F0005_LOAD_FORM_ESS_SUCCESS) {
        return {
            ...state,
            getDataFormEss: action.data
        };
    } else {
        return state;
    }
}