/**
 * @copyright 2020 @ DigiNet
 * @author DINHTHIEN
 * @create 7/9/2020
 * @Example
 */

import { types } from "./W09F2020_actions";
const initialState = {
    getFormGrid: [],
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.W09F2020_LOAD_FORM_GRID_SUCCESS: {
            return {
                ...state,
                getFormGrid: action.data,
            };
        }
        default:
            return state;
    }
}
