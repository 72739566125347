/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 10/24/2020
 * @Example 
 */
export const types = {
        W09F2030_GET_DATA_GRID: "W09F2030_GET_DATA_GRID",
        W09F2030_DELETE: "W09F2030_DELETE"
};

export function getDataGrid(params, cb) {
    return {
        type: types.W09F2030_GET_DATA_GRID,
        params,
        cb
    }
}

export function deleteData(params, cb) {
    return {
        type: types.W09F2030_DELETE,
        params,
        cb
    }
}
