/**
 * @copyright 2019 @ DigiNet
 * @author DINHTHIEN
 * @create 3/23/2020
 * @Example
 */
export const types = {
  W09F1501_LOAD_CBO_GENDERS: "W09F1501_LOAD_CBO_GENDERS",
  W09F1501_LOAD_CBO_GENDERS_SUCCESS:"W09F1501_LOAD_CBO_GENDERS_SUCCESS",
  W09F1501_LOAD_CBO_PROVINCES: "W09F1501_LOAD_CBO_PROVINCES",
  W09F1501_LOAD_CBO_PROVINCES_SUCCESS:"W09F1501_LOAD_CBO_PROVINCES_SUCCESS",
  W09F1501_LOAD_CBO_DIRECT_MANAGERS: "W09F1501_LOAD_CBO_DIRECT_MANAGERS",
  W09F1501_LOAD_CBO_DIRECT_MANAGERS_SUCCESS:"W09F1501_LOAD_CBO_DIRECT_MANAGERS_SUCCESS",
  W09F1501_LOAD_CBO_POSITIONS: "W09F1501_LOAD_CBO_POSITIONS",
  W09F1501_LOAD_CBO_POSITIONS_SUCCESS:"W09F1501_LOAD_CBO_POSITIONS_SUCCESS",

  W09F1501_GET_FORM: "W09F1501_GET_FORM",
  W09F1501_GET_FORM_SUCCESS: "W09F1501_GET_FORM_SUCCESS",

  W09F1501_ADD: "W09F1501_ADD",
  W09F1501_EDIT: "W09F1501_EDIT",
};

export function loadForm(params, cb) {
  return {
    type: types.W09F1501_GET_FORM,
    params,
    cb
  };
}

export function loadCboGenders(params, cb) {
  return {
    type: types.W09F1501_LOAD_CBO_GENDERS,
    params,
    cb
  };
}

export function loadCboProvinces(params, cb) {
  return {
    type: types.W09F1501_LOAD_CBO_PROVINCES,
    params,
    cb
  };
}

export function loadCboDirectManagers(params, cb) {
  return {
    type: types.W09F1501_LOAD_CBO_DIRECT_MANAGERS,
    params,
    cb
  };
}

export function loadCboPositions(params, cb) {
  return {
    type: types.W09F1501_LOAD_CBO_POSITIONS,
    params,
    cb
  };
}

export function add(params, cb) {
  return {
    type: types.W09F1501_ADD,
    params,
    cb
  };
}

export function edit(params, cb) {
  return {
    type: types.W09F1501_EDIT,
    params,
    cb
  };
}



