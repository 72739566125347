/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 10/26/2020
 * @Example
 */
export const types = {
  W09F2031_GET_CAPTION: "W09F2031_GET_CAPTION",
  W09F2031_GET_CAPTION_SUCCESS: "W09F2031_GET_CAPTION_SUCCESS",
  W09F2031_LOAD_CBO_CODE: "W09F2031_LOAD_CBO_CODE",
  W09F2031_LOAD_CBO_CODE_SUCCESS: "W09F2031_LOAD_CBO_CODE_SUCCESS",

};

export function getCaption(cb) {
  return {
    type: types.W09F2031_GET_CAPTION,
    cb
  };
}

export function getCboCode(cb) {
  return {
    type: types.W09F2031_LOAD_CBO_CODE,
    cb
  };
}


