import {types} from "./W09F2000_actions";

const initialState = {
    getInfo:null,
};

export default function (state = initialState, action = {}) {
    if (action.type === types.W09F2000_GET_INFO_SUCCESS) {
        return {
            ...state,
            getInfo: action.data
        };
    } else {
        return state;
    }
}