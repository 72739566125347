
export const types = {
    W00F0005_LOAD_FORM_ESS: "W00F0005_LOAD_FORM_ESS",
    W00F0005_LOAD_FORM_ESS_SUCCESS: "W00F0005_LOAD_FORM_ESS_SUCCESS",
    W00F0005_UPDATE: "W00F0005_UPDATE",
};

export function getFormEss(cb) {
    return{
        type: types.W00F0005_LOAD_FORM_ESS,
        cb
    }
}

export function update(params, cb) {
    return{
        type: types.W00F0005_UPDATE,
        params,
        cb
    }
}