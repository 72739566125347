/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 2/12/2020
 * @Example 
 */
export const types = {
  W09F2070_LOAD_GRID: "W09F2070_GET_DATA_GRID",
  W09F2070_LOAD_GRID_SUCCESS: "W09F2070_GET_DATA_GRID_SUCCESS",
  W09F2070_LOAD_CBO_DISREWARD_FORM: "W09F2070_LOAD_CBO_DISREWARD_FORM",
  W09F2070_LOAD_CBO_DISREWARD_FORM_SUCCESS: "W09F2070_LOAD_CBO_DISREWARD_FORM_SUCCESS",
  W09F2070_LOAD_CBO_PRODIS_REWARD_LEVEL: "W09F2070_LOAD_CBO_PRODIS_REWARD_LEVEL",
  W09F2070_LOAD_CBO_PRODIS_REWARD_LEVEL_SUCCESS:"W09F2070_LOAD_CBO_PRODIS_REWARD_LEVEL_SUCCESS",

  W09F2070_LOAD_CBO_DISVIOLATION_ACTGROUP: "W09F2070_LOAD_CBO_DISVILATION_ACTGROUP",
  W09F2070_LOAD_CBO_DISVIOLATION_ACTGROUP_SUCCESS: "W09F2070_LOAD_CBO_W09F2070_LOAD_CBO_DISVILATION_ACTGROUP_SUCCESS",
  W09F2070_LOAD_CBO_DIS_VIOLATION_ACT: "W09F2070_LOAD_CBO_DIS_VIOLATION_ACT",
  W09F2070_LOAD_CBO_DIS_VIOLATION_ACT_SUCCESS:"W09F2070_LOAD_CBO_DIS_VIOLATION_ACT_SUCCESS",
  W09F2070_LOAD_APPROVAL_STATUS: "W09F2070_LOAD_APPROVAL_STATUS",
  W09F2070_LOAD_APPROVAL_STATUS_SUCCESS:"W09F2070_LOAD_APPROVAL_STATUS_SUCCESS",
  W09F2070_REWARD_CAPTION: "W09F2070_REWARD_CAPTION",
  W09F2070_REWARD_CAPTION_SUCCESS:"W09F2070_REWARD_CAPTION_SUCCESS",
  W09F2070_EDIT_DATA:"W09F2070_EDIT_DATA",
  W09F2070_EDIT_DATA_SUCCESS:"W09F2070_EDIT_DATA_SUCCESS",
  W09F2070_SAVE_DATA:"W09F2070_SAVE_DATA",
  W09F2070_SAVE_DATA_SUCCESS:"W09F2070_SAVE_DATA_SUCCESS",
  W09F2070_DELETE_DATA: "W09F2070_DELETE_DATA",
  W09F2070_GET_FORM: "W09F2070_GET_FORM",
  W09F2070_GET_FORM_SUCCESS: "W09F2070_GET_FORM_SUCCESS",
};

export function loadForm(params, cb) {
  return {
    type: types.W09F2070_GET_FORM,
    params,
    cb
  };
}

export function loadDataGrid(params, cb) {
  return {
    type: types.W09F2070_LOAD_GRID,
    params,
    cb
  };
}


export function loadAppStatus(params, cb) {
  return {
    type: types.W09F2070_LOAD_APPROVAL_STATUS,
    params,
    cb
  };
}

export function loadCboDisRewardForm(cb) {
  return {
    type: types.W09F2070_LOAD_CBO_DISREWARD_FORM,
    cb
  };
}

export function loadCboProDisRewardLevel(cb) {
  return {
    type: types.W09F2070_LOAD_CBO_PRODIS_REWARD_LEVEL,
    cb
  };
}

export function loadCboDisViolationActGroup(params, cb) {
  return {
    type: types.W09F2070_LOAD_CBO_DISVIOLATION_ACTGROUP,
    params,
    cb
  };
}

export function loadCboDisViolationAct(params, cb) {
  return {
    type: types.W09F2070_LOAD_CBO_DIS_VIOLATION_ACT,
    params,
    cb
  };
}

export function loadRewardCaption(params, cb) {
  return {
    type: types.W09F2070_REWARD_CAPTION,
    params,
    cb
  };
}

export function edit(params, cb) {
  return {
    type: types.W09F2070_EDIT_DATA,
    params,
    cb
  };
}

export function save(params, cb) {
  return {
    type: types.W09F2070_SAVE_DATA,
    params,
    cb
  };
}

export function deleteDataGrid(params, cb) {
  return {
    type: types.W09F2070_DELETE_DATA,
    params,
    cb
  };
}
