/**
 * @copyright 2019 @ DigiNet
 * @author DINHTHIEN
 * @create 3/23/2020
 * @Example
 */

import {types} from "./W09F1501_actions";

const initialState = {
    getForm: [],
    getCboGenders:[],
    getCboProvinces:[],
    getCboPositions:[],
    getCboDirectManagers:[]
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.W09F1501_GET_FORM_SUCCESS: {
            return {...state, getForm: action.data};
        }
        case types.W09F1501_LOAD_CBO_GENDERS_SUCCESS: {
            return {...state, getCboGenders: action.data};
        }
        case types.W09F1501_LOAD_CBO_PROVINCES_SUCCESS: {
            return {...state, getCboProvinces: action.data};
        }
        case types.W09F1501_LOAD_CBO_POSITIONS_SUCCESS: {
            return {...state, getCboPositions: action.data};
        }
        case types.W09F1501_LOAD_CBO_DIRECT_MANAGERS_SUCCESS: {
            return {...state, getCboDirectManagers: action.data};
        }
        default:
            return state;
    }
}
