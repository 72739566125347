/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 10/24/2020
 * @Example 
 */

import Api from '../../../services/api';
import { cancel, take, takeLatest } from "redux-saga/effects";
import { types } from "./W09F2030_actions";
import { delay } from "redux-saga";

export default function W09F2030Sagas() {
    return [
        watchGetForm(),
        watchDeleteData(),

    ];
}

export function* getDataGrid(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w09f2030/load-form', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get data grid error',e);
    }

}

export function* watchGetForm() {
    while (true) {
        const watcher = yield takeLatest(types.W09F2030_GET_DATA_GRID, getDataGrid);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* deleteData(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w09f2030/delete', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('delete data error',e);
    }

}

export function* watchDeleteData() {
    while (true) {
        const watcher = yield takeLatest(types.W09F2030_DELETE, deleteData);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}
