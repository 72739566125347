/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 10/24/2020
 * @Example 
 */

export const types = {
    W09F2032_GET_CAPTION: "W09F2032_GET_CAPTION",
    W09F2032_GET_CAPTION_SUCCESS: "W09F2032_GET_CAPTION_SUCCESS",
    W09F2032_GET_CBO_CODE: "W09F2032_GET_CBO_CODE",
    W09F2032_GET_CBO_CODE_SUCCESS: "W09F2032_GET_CBO_CODE_SUCCESS",
    W09F2032_GET_CBO_REASON: "W09F2032_GET_CBO_REASON",
    W09F2032_GET_CBO_REASON_SUCCESS: "W09F2032_GET_CBO_REASON_SUCCESS",
    W09F2032_GET_DATA_FORM: "W09F2032_GET_DATA_FORM",
    W09F2032_ADD_DATA: "W09F2032_ADD_DATA",
    W09F2032_EDIT_DATA: "W09F2032_EDIT_DATA",
};


export function getCaption(cb) {
    return {
        type: types.W09F2032_GET_CAPTION,
        cb
    };
}

export function getCboCode(cb) {
    return {
        type: types.W09F2032_GET_CBO_CODE,
        cb
    };
}

export function getCboReason(cb) {
    return {
        type: types.W09F2032_GET_CBO_REASON,
        cb
    };
}

export function getForm(params, cb) {
    return {
        type: types.W09F2032_GET_DATA_FORM,
        params,
        cb
    };
}

export function edit(params, cb) {
    return {
        type: types.W09F2032_EDIT_DATA,
        params,
        cb
    };
}

export function add(params, cb) {
    return {
        type: types.W09F2032_ADD_DATA,
        params,
        cb
    };
}

