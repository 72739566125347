export const types = {
    W09F2023_GET_FROM: 'W09F2023_GET_FROM',
    W09F2023_GET_FROM_SUCCESS: 'W09F2023_GET_FROM_SUCCESS',
};

export function getForm(params, cb) {
    return{
        type: types.W09F2023_GET_FROM,
        params,
        cb
    }
}