/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 10/26/2020
 * @Example 
 */

import Api from "../../../services/api";
import { cancel, put, take, takeLatest } from "redux-saga/effects";
import { types } from "./W09F2031_actions";
import { delay } from "redux-saga";

export default function W09F2031Sagas() {
  return [
    watchGetCaption(),
    watchGetCboCode(),
  ];
}

export function* getCaption(data) {
  try {
    yield delay(300);
    const response = yield Api.get("/w09f2031/load-caption", data.params);
    if (response && response.data) {
      yield put({ type: types.W09F2031_GET_CAPTION_SUCCESS, data: response.data });
      data.cb && data.cb(null, response.data);
    } else {
      data.cb && data.cb(response, null);
    }
  } catch (e) {
    console.log("get Caption error",e);
  }
}

export function* watchGetCaption() {
  while (true) {
    const watcher = yield takeLatest(types.W09F2031_GET_CAPTION, getCaption);
    yield take(["LOGOUT", "NETWORK"]);
    yield cancel(watcher);
  }
}

//get data grid
export function* getCboCode(data) {
  try {
    yield delay(300);
    const response = yield Api.get("/w09f2152/load-cbo-code", data.params);
    if (response && response.data) {
      yield put({
        type: types.W09F2031_LOAD_CBO_CODE_SUCCESS,
        data: response.data
      });
      data.cb && data.cb(null, response.data);
    } else {
      data.cb && data.cb(response, null);
    }
  } catch (e) {
    console.log("get cbo code error",e);
  }
}

export function* watchGetCboCode() {
  while (true) {
    const watcher = yield takeLatest(types.W09F2031_LOAD_CBO_CODE, getCboCode);
    yield take(["LOGOUT", "NETWORK"]);
    yield cancel(watcher);
  }
}
