/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 9/25/2020
 * @Example
 */

import Api from "../../../services/api";
import {cancel, put, take, takeLatest} from "redux-saga/effects";
import {types} from "./W09F2150_actions";
import {delay} from "redux-saga";

export default function W09F2150Sagas() {
    return [
        watchGetDynamicFields(),
        watchGetDataGrid(),
        watchGetForm(),
        watchDeleteData(),
        watchEditData(),
        watchSaveData(),
        watchLoadCaption(),
    ];
}

export function* getDynamicFields(data) {
    try {
        yield delay(300);
        const response = yield Api.get("/w09f2151/load-collumn");
        if (response && response.data) {
            yield put({type: types.W09F2150_LOAD_DYNAMIC_FIELD_SUCCESS, data: response.data});
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get dynamic fields error", e);
    }
}

export function* watchGetDynamicFields() {
    while (true) {
        const watcher = yield takeLatest(types.W09F2150_LOAD_DYNAMIC_FIELD, getDynamicFields);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getForm(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w09f2151/load-form", data.params);
        if (response && response.data) {
            yield put({type: types.W09F2150_GET_FORM_SUCCESS, data: response.data});
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get form error", e);
    }
}

export function* watchGetForm() {
    while (true) {
        const watcher = yield takeLatest(types.W09F2150_GET_FORM, getForm);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

//get data grid
export function* getDataGrid(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w09f2150/load-form", data.params);
        if (response && response.data) {
            yield put({type: types.W09F2150_LOAD_GRID_SUCCESS, data: response.data});
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get data grid error");
    }
}

export function* watchGetDataGrid() {
    while (true) {
        const watcher = yield takeLatest(types.W09F2150_LOAD_GRID, getDataGrid);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* editData(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w09f2151/edit", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("edit data error");
    }
}

export function* watchEditData() {
    while (true) {
        const watcher = yield takeLatest(types.W09F2150_EDIT_DATA, editData);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* saveData(data) {
    try {
        yield delay(300);
        const response = yield Api.post("/w09f2151/add", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("edit data error");
    }
}

export function* watchSaveData() {
    while (true) {
        const watcher = yield takeLatest(types.W09F2150_SAVE_DATA, saveData);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

//deleteData
export function* deleteData(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w09f2150/delete", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("delete data error");
    }
}

export function* watchDeleteData() {
    while (true) {
        const watcher = yield takeLatest(types.W09F2150_DELETE_DATA, deleteData);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

//loadCaption
export function* loadCaption(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w09f2151/load-cbo-code", data.params);
        if (response && response.data) {
            yield put({type: types.W09F2150_LOAD_CAPTION_SUCCESS, data: response.data});
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("loadCaption data error");
    }
}

export function* watchLoadCaption() {
    while (true) {
        const watcher = yield takeLatest(types.W09F2150_LOAD_CAPTION, loadCaption);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

