/**
 * @copyright 2019 @ DigiNet
 * @author Minh Trung
 * @create 13/02/2020
 * @Example
 */

export const types = {
  W09F2071_GET_REWARD_CAPTION : "W09F2071_GET_REWARD_CAPTION",
  W09F2071_GET_REWARD_CAPTION_SUCCESS : "W09F2071_GET_REWARD_CAPTION_SUCCESS",
};

export function loadRewardCaption(params,cb) {
    return {
        type : types.W09F2071_GET_REWARD_CAPTION,
        params,
        cb
    };
}