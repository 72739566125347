/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 2/12/2020
 * @Example
 */

import {types} from "./W09F2010_actions";

const initialState = {
    getDataGrid: [],
    getCboPeriod: [],
    getCboAppStatus: [],
    getCboProDisRewardLevel: [],
    getCboDisRewardForm: [],
    getCboDisViolationActGroup: [],
    getCboDisViolationAct: [],
    getRewardCaption: [],
    getForm: [],
    getDynamicFields: [],
    getCboResignForm: [],
    getCboReason: [],
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.W09F2010_LOAD_GRID_SUCCESS: {
            return {
                ...state,
                getDataGrid: action.data
            };
        }
        case types.W09F2010_LOAD_APPROVAL_STATUS_SUCCESS: {
            return {
                ...state,
                getCboAppStatus: action.data
            };
        }
        case types.W09F2010_GET_FORM_SUCCESS: {
            return {
                ...state,
                getForm: action.data
            };
        }
        case types.W09F2010_LOAD_CBO_RESIGN_FORM_SUCCESS: {
            return {
                ...state,
                getCboResignForm: action.data
            }
        }
        case types.W09F2010_LOAD_CBO_REASON_SUCCESS: {
            return {
                ...state,
                getCboReason: action.data
            }
        }
        default:
            return state;
    }
}
