/**
 * @copyright 2019 @ DigiNet
 * @author THIEN
 * @create 12/5/2019
 * @Example
 */

import { types } from "./W09F2005_actions";

const initialState = {
  listEmployee: { rows: [], total: 0 },
  statusTab: []
};

export default function(state = initialState, action = {}) {
  switch (action.type) {
    case types.W09F2005_GET_LISTEMPLOYEES_SUCCESS: {
      const { total, rows } = action.data;
      return { ...state, listEmployee: { rows, total } };
    }
    case types.W09F2005_GET_TABSTATUS_SUCCESS: {
      return { ...state, statusTab: action.data };
    }
    default: {
      return state;
    }
  }
}
