/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 2/12/2020
 * @Example
 */

import {types} from "./W09F2070_actions";

const initialState = {
    getDataGrid: [],
    getCboPeriod: [],
    getCboAppStatus: [],
    getCboProDisRewardLevel: [],
    getCboDisRewardForm: [],
    getCboDisViolationActGroup: [],
    getCboDisViolationAct: [],
    getRewardCaption: [],
    getForm: [],
    getDynamicFields: [],
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.W09F2070_LOAD_GRID_SUCCESS: {
            return {
                ...state,
                getDataGrid: action.data
            };
        }
        case types.W09F2070_LOAD_APPROVAL_STATUS_SUCCESS: {
            return {
                ...state,
                getCboAppStatus: action.data
            };
        }
        case types.W09F2070_GET_FORM_SUCCESS: {
            return {...state, getForm: action.data};
        }
        case types.W09F2070_LOAD_CBO_DISREWARD_FORM_SUCCESS: {
            return {...state, getCboDisRewardForm: action.data};
        }
        case types.W09F2070_LOAD_CBO_PRODIS_REWARD_LEVEL_SUCCESS: {
            return {...state, getCboProDisRewardLevel: action.data};
        }
        case types.W09F2070_REWARD_CAPTION_SUCCESS: {
            return {...state, getRewardCaption: action.data}
        }
        case types.W09F2070_LOAD_CBO_DISVIOLATION_ACTGROUP_SUCCESS: {
            return {...state, getCboDisViolationActGroup: action.data}
        }
        case types.W09F2070_LOAD_CBO_DIS_VIOLATION_ACT_SUCCESS: {
            return {...state, getCboDisViolationAct: action.data}
        }
        default:
            return state;
    }
}
