/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 10/31/2019
 * @Example 
 */

import Api from '../../../services/api';
import {put, cancel, take, takeEvery} from "redux-saga/effects";
import {types} from "../../W0X/W09F2000/W09F2000_actions";
import {delay} from "redux-saga";

export default function W09F2000Sagas() {
    return [
        watchGetInfo(),
    ];
}

export function* getInfo(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w09f2000/get-data-tab', data.params);
        if (response && response.data) {
            yield put({type: types.W09F2000_GET_INFO_SUCCESS, data: response.data});
            data.cb && data.cb(null, response.data)
        }
        else{
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get info error', e);
    }
}

export function* watchGetInfo() {
    while (true){
        const watcher = yield takeEvery(types.W09F2000_GET_INFO, getInfo);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}