/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 12/7/2020
 * @Example
 */


import Api from '../../../services/api';
import {put, cancel, take, takeLatest} from "redux-saga/effects";
import {types} from "../../W0X/W00F0005/W00F0005_actions";
import {delay} from "redux-saga";

export default function W00F0005Sagas() {
    return [
        watchGetFormEss(),
        watchUpdate(),
    ];
}

export function* getFormEss(data) {
    try {
        yield delay(300);
        const response = yield Api.get('/w00f0005/load-form-ess');
        if (response && response.data) {
            yield put({type: types.W00F0005_LOAD_FORM_ESS_SUCCESS, data: response.data});
            data.cb && data.cb(null, response.data)
        }
        else{
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get info error', e);
    }
}

export function* watchGetFormEss() {
    while (true){
        const watcher = yield takeLatest(types.W00F0005_LOAD_FORM_ESS, getFormEss);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}

export function* update(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w00f0005/update-status',data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else{
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get info error', e);
    }
}

export function* watchUpdate() {
    while (true){
        const watcher = yield takeLatest(types.W00F0005_UPDATE, update);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}