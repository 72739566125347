/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 2/12/2020
 * @Example
 */
export const types = {
  W09F2010_LOAD_GRID: "W09F2010_GET_DATA_GRID",
  W09F2010_LOAD_GRID_SUCCESS: "W09F2010_GET_DATA_GRID_SUCCESS",
  W09F2010_LOAD_CBO_RESIGN_FORM:"W09F2010_LOAD_CBO_RESIGN_FORM",
  W09F2010_LOAD_CBO_RESIGN_FORM_SUCCESS:"W09F2010_LOAD_CBO_RESIGN_FORM_SUCCESS",
  W09F2010_LOAD_APPROVAL_STATUS: "W09F2010_LOAD_APPROVAL_STATUS",
  W09F2010_LOAD_APPROVAL_STATUS_SUCCESS:"W09F2010_LOAD_APPROVAL_STATUS_SUCCESS",
  W09F2010_EDIT_DATA:"W09F2010_EDIT_DATA",
  W09F2010_EDIT_DATA_SUCCESS:"W09F2010_EDIT_DATA_SUCCESS",
  W09F2010_SAVE_DATA:"W09F2010_SAVE_DATA",
  W09F2010_SAVE_DATA_SUCCESS:"W09F2010_SAVE_DATA_SUCCESS",
  W09F2010_DELETE_DATA: "W09F2010_DELETE_DATA",
  W09F2010_GET_FORM: "W09F2010_GET_FORM",
  W09F2010_GET_FORM_SUCCESS: "W09F2010_GET_FORM_SUCCESS",
  W09F2010_LOAD_CBO_REASON: "W09F2010_LOAD_CBO_REASON",
  W09F2010_LOAD_CBO_REASON_SUCCESS: "W09F2010_LOAD_CBO_REASON_SUCCESS",
  W09F2010_GET_VIOLATEDAYS: "W09F2010_GET_VIOLATEDAYS",
};

export function loadForm(params, cb) {
  return {
    type: types.W09F2010_GET_FORM,
    params,
    cb
  };
}

export function loadDataGrid(params, cb) {
  return {
    type: types.W09F2010_LOAD_GRID,
    params,
    cb
  };
}


export function loadAppStatus(params, cb) {
  return {
    type: types.W09F2010_LOAD_APPROVAL_STATUS,
    params,
    cb
  };
}

export function loadCboResignForm(params, cb) {
  return {
    type: types.W09F2010_LOAD_CBO_RESIGN_FORM,
    params,
    cb
  };
}

export function getCboReason(params, cb) {
  return {
    type: types.W09F2010_LOAD_CBO_REASON,
    params,
    cb
  };
}

export function edit(params, cb) {
  return {
    type: types.W09F2010_EDIT_DATA,
    params,
    cb
  };
}

export function save(params, cb) {
  return {
    type: types.W09F2010_SAVE_DATA,
    params,
    cb
  };
}

export function deleteDataGrid(params, cb) {
  return {
    type: types.W09F2010_DELETE_DATA,
    params,
    cb
  };
}

export function getViolateDays(params, cb) {
  return {
    type: types.W09F2010_GET_VIOLATEDAYS,
    params,
    cb
  };
}
